<template>
  <ion-page>
    <ion-content class="ion-padding-horizontal">
      <div class="main-content tablet-centered">
        <div>
          <div class="ion-margin-bottom">
            <h2 class="mb-4 ion-text-center">{{$t('forgotEmail.title')}}</h2>
            <p class="fz-14 fw-300">{{$t('forgotEmail.subTitle')}}</p>
          </div>
          <div>
            <div class="ion-margin-bottom">
              <AppInput
                :value="bignumber"
                :label="$t('forgotEmail.usernameLabel')"
                class="w-100"
                type="text"
                @input:update="email = $event"
              />

              <div class="ion-text-end">
                <router-link
                  to="/forgot-password"
                  class="fz-12 fw-300 simple-link has-text-white"
                > {{$t('forgotEmail.forgotBigNumber')}} </router-link>
              </div>
            </div>

            <div class="ion-padding-top">
              <ion-button
                class="ion-margin-bottom"
                expand="block"
                mode="ios"
                @click="submit"
              >
                {{$t('commonKeys.submit')}}
              </ion-button>

              <ion-button
                expand="block"
                :icon-="arrowBackCircleOutline"
                fill="outline"
                mode="ios"
                @click="$router.push('/login')"
              >
                <IonIcon
                  slot="start"
                  :icon="arrowBackCircleOutline"
                />
                {{$t('forgotPassword.backToLoginLink')}}
              </ion-button>
            </div>
          </div>
        </div>
      </div>
      <IonLoading :is-open="loading"/>
    </ion-content>
  </ion-page>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';
import { defineComponent } from 'vue';
import { ref } from '@vue/reactivity';
import useRequestToast from "@/composables/useRequestToast";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { IonButton, IonIcon, IonPage, IonContent, IonLoading } from '@ionic/vue';
import { arrowBackCircleOutline } from 'ionicons/icons';



export default defineComponent({
  name: "ForgotEmail",

  components: {
    AppInput,
    IonButton,
    IonIcon,
    IonPage,
    IonContent,
    IonLoading,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const bignumber = ref(null);
    const loading = ref(false);

    const { openErrorToast, openSuccessToast } = useRequestToast();

    const handleSuccessRecovery = (data) => {
      openSuccessToast('We have emailed your password reset link!');
      router.push('/login');
    };

    const submit = () => {
      loading.value = true;
      store.dispatch('authentication/forgotEmail', { bignumber: bignumber.value })
        .then(handleSuccessRecovery)
        .catch(openErrorToast)
        .finally(() => { loading.value = false; });
    };

    return {
      submit,
      bignumber,
      loading,
      arrowBackCircleOutline,
    };
  },
});
</script>

<style scoped>
  .main-content {
    padding-top: 10vh;
  }
</style>
